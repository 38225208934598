<template>
  <b-container>
    <b-row>
      <b-col>
        <b-card>
          <h1 class="mb-0">Dashboard Admin</h1>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { BContainer, BRow, BCol, BCard } from "bootstrap-vue";
export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {};
  },
  created() {
  },

  beforeRouteEnter(to, from, next) {
    if(localStorage.getItem('userData')){
      const role = JSON.parse(localStorage.getItem("userData")).role;
      if (role === 'admin') {
        next(true);
      } else {
        next("/error-404");
      }
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
